var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-progress-circular", {
        staticClass: "mt-6",
        attrs: { size: 135, width: 20, color: "#E7E7FA", indeterminate: "" },
      }),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex align-center justify-center mt-4" },
      [
        _c("span", {
          staticClass: "apexcharts-legend-marker",
          staticStyle: {
            background: "rgb(231, 231, 250) !important",
            color: "rgb(231, 231, 250)",
            height: "12px",
            width: "12px",
            "border-width": "0px",
            "border-color": "rgb(255, 255, 255)",
            "border-radius": "12px",
          },
          attrs: { rel: "1", "data:collapsed": "false" },
        }),
        _c(
          "span",
          {
            staticClass: "apexcharts-legend-text",
            staticStyle: {
              color: "rgb(55, 61, 63)",
              "font-size": "12px",
              "font-weight": "400",
              "font-family": "Helvetica, Arial, sans-serif",
            },
            attrs: {
              rel: "1",
              i: "0",
              "data:default-text": "Nenhum%20dado%20dispon%C3%ADvel",
              "data:collapsed": "false",
            },
          },
          [_vm._v("Nenhum dado disponível")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }