<template>
  <div>
    <v-progress-circular class="mt-6" :size="135" :width="20" color="#E7E7FA" indeterminate></v-progress-circular>
    <div class="d-flex align-center justify-center mt-4">
      <span
        class="apexcharts-legend-marker"
        style="
          background: rgb(231, 231, 250) !important;
          color: rgb(231, 231, 250);
          height: 12px;
          width: 12px;
          border-width: 0px;
          border-color: rgb(255, 255, 255);
          border-radius: 12px;
        "
        rel="1"
        data:collapsed="false"
      ></span>
      <span
        class="apexcharts-legend-text"
        style="color: rgb(55, 61, 63); font-size: 12px; font-weight: 400; font-family: Helvetica, Arial, sans-serif"
        rel="1"
        i="0"
        data:default-text="Nenhum%20dado%20dispon%C3%ADvel"
        data:collapsed="false"
        >Nenhum dado disponível</span
      >
    </div>
  </div>
</template>
<script>
export default {
  name: 'LoadingCircular',
  components: {},

  data() {
    return {};
  },
};
</script>
